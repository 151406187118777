<template>
    <div class="surface-card p-6 border-1 surface-border">
        <data-table-view
            endpoint="user-reals"
            headline="Benutzer"
        >
            <template slot="actions">
                <router-link to="/users/create">
                    <Button
                        label="Neuen Benutzer erstellen"
                        class="p-button-primary p-button-sm"
                    />
                </router-link>
            </template>
            <Column field="username" header="Benutzername" sortable>
                <template #body="slotProps">
                    {{ slotProps.data.displayName }}
                    <template v-if="!slotProps.data.active">
                        <small>
                            (inaktiv)
                        </small>
                    </template>
                </template>
            </Column>
            <Column field="email" header="E-Mail" filterMatchMode="contain" ref="email" :sortable="true"/>

            <Column field="lastLogin" header="Letzter Login" sortable>
                <template #body="slotProps">
                    {{ slotProps.data.lastLogin|formatDate('LLL') }}
                </template>
            </Column>

            <Column field="type" header="Typ">
                <template #body="slotProps">
                    {{ slotProps.data.type|dataTrans('model.userReal.type') }}
                </template>
            </Column>

            <Column header="Aktiv">
                <template #body="slotProps">
                    <i class="pi"
                       :class="{
                            'true-icon pi-check-circle': slotProps.data.active,
                        }"
                    ></i>
                </template>
            </Column>
            <Column field="roles" header="Benutzergruppen">
                <template #body="slotProps">
                    <template v-for="(userGroupName, i) in slotProps.data.userGroupNames">
                        {{ userGroupName }}
                        <template v-if="i < (Object.keys(slotProps.data.userGroupNames).length - 1)">,</template>
                    </template>
                </template>
            </Column>
            <Column header="" :styles="{'text-align': 'right'}">
                <template #body="slotProps">
                    <router-link
                        :to="'/users/' + slotProps.data.id"
                        v-if="slotProps.data.userCanUpdate"
                    >
                        <Button
                            label="Bearbeiten"
                            class="p-button-secondary p-button-sm cursor-pointer"
                        />
                    </router-link>
                </template>
            </Column>
        </data-table-view>
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import Column from 'primevue/column';
    import DataTableView from "@/components/DataTableView";
    import InputText from "primevue/inputtext/InputText";
    import TriStateCheckbox from "primevue/tristatecheckbox";

    export default {
        components: {
            InputText,
            Button,
            DataTableView,
            Column,
            TriStateCheckbox
        },
        methods: {}
    }
</script>
